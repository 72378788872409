*{

    margin: 0;
    padding: 0;

}

:root {

    overflow-x: hidden;
    /* cursor: url('../../img/rocket.gif'), auto; */

}

body {

    cursor: url('https://greatlaunches.ffwd.rocks/assets/mao1.png'), auto;
    /* cursor: url(../assets/mao1.png), auto; */

}

.flexDisplay {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.homePage {

    width: 100vw;
    /* background-color: black; */
    font-family: Poppins, 'sans-serif';

}

.boxProject p {

    margin: 3px 0;
    font-size: 11pt;
    text-indent: .5em;
    text-align: center;

}

.boxProject img {

    width: 100px;
    border-radius: 10px;

}

.boxProject {

    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;

    color: black;
    /* border: 1px solid black; */
    background-color: white;

}

.sixthScreen > div ul img {

    width: 80px;
    margin: 0 15px;
    cursor: pointer;

}

.sixthScreen  > div ul {

    width: 100%;
    display: flex;
    list-style: none;
    margin-bottom: 5vh;
    justify-content: center;

}

.sixthScreen > div {

    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.sixthScreen {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100vw;
    padding-top: 15vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-image: url('../../img/asteroids.png');

}

.fifthScreen ul img{

    width: 80px;

}

.fifthScreen ul {

    width: 75%;
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;

}

.fifthScreen {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100vw;
    padding-top: 15vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-image: url('../../img/asteroids.png');

}

.fourthScreen  > div > div span{
    
    /* color: white; */
    font-size: 20pt;
    position: absolute;
    margin: 0 auto;

}

.fourthScreen  > div > div svg {

    width: 45%;

}

.fourthScreen  > div > div {

    width: 85%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.fourthScreen > div a {

    text-decoration: none;

}

.fourthScreen  > div ul {

    width: 80%;
    display: flex;
    margin-bottom: 5vh;
    justify-content: space-between;

}

.fourthScreen > div {

    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.fourthScreen  {

    padding-top: 3vh;
    width: 100vw;
    /* padding-bottom: 15vh; */
    background-color: black;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-image: url('../../img/infinitGif.gif');

}

.thirdScreen > div li {

    list-style: none;

}

.thirdScreen > div {

    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.thirdScreen > div h3{

    margin-top: 5vh;

}

.thirdScreen {

    width: 100vw;
    padding-bottom: 15vh;
    background-size: cover;
    /* background-color: black; */
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    /* background-image: url('../../img/asteroids3.png'); */
    background-image: url('../../img/asteroids.png');

}

.secondScreen {

    width: 100vw;
    /* min-height: 100vh; */
    background-size: cover;
    /* background-color: black; */
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-image: url('../../img/asteroids.png');

}

.secondScreen > div {

    width: 85%;

}

.initialScreen h1 {

    font-size: 30pt;

}

.initialScreen h2 {

    margin-top: 10vh;
    animation: arrowToTop 2s infinite;
    
}

.initialScreen > div {

    width: 90%;
    height: 90vh;
    /* background-image: linear-gradient(to right, black 40%,rgba(255,255,255,0) 100%); */
    /* background-image: linear-gradient(45deg, black 60%, transparent);
    background-position: center center;
    background-repeat: no-repeat; */

}

.initialScreen {

    width: 100vw;
    height: 100vh;
    background-color: black;
    justify-content: flex-start;
    /* background-size: 50%; */
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url('../../img/astronauta3.gif');
    /* background-image: url('../../img/babYoda.gif'); */

}

.boxTextBlack {

    padding: 15px;
    margin: 10px 0;
    color: white;
    border-radius: 5px;
    background-color: black;

}

.boxText {

    padding: 5px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: white;

}

.boxTextBorder {

    padding: 5px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;

}

.boxTextBlackBorder {

    width: 75%;
    text-align: center;
    padding: 15px;
    margin: 10px 0;
    color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 5px black;
    background-color: black;

}

.wave{

    width: 100vw;
    margin-bottom: -15px;

}


@keyframes arrowToTop {

    0%, 100% {

        transform: 
            translateY(15px);

    }
    50% {

        transform: 
            translateY(0px);

    }
    
}

@media screen and (max-width: 768px) {

    .boxProject {

        margin: 10px 0;

    }

    .sixthScreen {

        /* background-size: auto; */
        background-attachment: local;

    }

    .fifthScreen ul img {

        width: 60px;

    }

    .fifthScreen h2 {

        font-size: 12pt;
        margin-top: 30px;
        text-align: center;

    }

    .fifthScreen {

        background-attachment: local;

    }

    .fourthScreen > div a {

        text-decoration: none;

    }

    .fourthScreen  > div ul {

        flex-direction: column;

    }

    .fourthScreen  > div > div span{
    
        font-size: 15pt;
        position: absolute;
        margin: 0 auto;
    
    }

    .fourthScreen  > div > div svg {

        width: 90%;

    }

    .fourthScreen {

        /* background-size: auto; */
        background-attachment: local;

    }

    .thirdScreen {

        /* background-size: auto; */
        background-attachment: local;
        /* background-position: center center; */

    }

    .secondScreen {

        /* background-size: auto; */
        background-attachment: local;
        /* background-position: center center; */

    }

    .wave {

        margin-bottom: auto;

    }

    .boxTextBorder {

        max-width: 80vw;

    }
    
}